<template>
  <div>
    <v-card>
      <VCardTitle class="headline pb-0" :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
        <v-btn plain :color="$vuetify.theme.dark ? 'grey lighten-3' : 'grey'" dense @click="onBack">
          <v-icon left>mdi-chevron-left</v-icon>{{ $t("btn.back") }}
        </v-btn>
      </VCardTitle>

      <v-card-text class="pa-0">
        <v-container>
          <v-row>
            <v-col cols="12" md="5" class="d-flex flex-column">
              <p-carousel :images="offer?.images" :type="'info'" class="mb-3"/>

              <v-card outlined class="pa-3 flex-grow-1">
<!--                <div class="font-weight-bold">Основні характеристики</div>
                <div class="mt-2">Клас: {{ product.class }}</div>
                <div>Кількість швидкостей: {{ product.speeds }}</div>
                <div>Діаметр колеса: {{ product.wheelDiameter }}</div>
                <div>Матеріал рами: {{ product.frameMaterial }}</div>
                <div>Тип гальм: {{ product.brakeType }}</div>
                <v-btn text color="primary" class="mt-2">Детальніше про товар</v-btn>-->
                <h2 class="mt-4 mb-2">{{ $t('form.description') }}</h2>
                <div 
                  class="description-wrapper"
                  ref="descriptionWrapper"
                  :class="{ 'expanded': showFullDescription }"
                >
                  <p ref="descriptionText">{{ offer.description }}</p>
                </div>
                <v-btn
                  v-if="isDescriptionTooLong"
                  text
                  color="primary"
                  class="mt-2"
                  @click="toggleDescription"
                >
                  {{ showFullDescription ? $t('btn.fullDescriptionCollapse') : $t('btn.fullDescriptionReadinfull') }}
                </v-btn>
              </v-card>
            </v-col>

            <!-- Right Section: Product Details -->
            <v-col cols="12" md="7">
              <div class="d-flex align-center">
                <div class="text-h5 font-weight-bold">{{ offer.name }}</div>
              </div>
              <div class="subtitle-1 text-grey">{{ $t('form.code') }}: {{ offer.id }}</div>

              <v-card class="mt-4">
                <v-card-text>
                  <v-row>
                    <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : 7">
                      <div class="font-weight-bold text-success">{{ $t('form.instock') }}</div>
                      <div class="text-h5 font-weight-bold">{{ $t('offersCatalog.price') }}: {{ offer?.prices[0]?.price ? offer?.prices[0]?.price : '0.00' }} {{ $t('form.UAH') }}</div>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : 5" class="d-flex align-center">
                      <v-btn class="ml-auto" color="primary" @click="createStream">{{ $t('btn.create_a_stream') }}</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card class="mt-4">
                <v-card-text>
                  <div class="mb-1 text-h5 font-weight-bold">{{ $t('statistic.purchase_percentage') }} {{" : "}} {{ offer?.prices[0]?.price ? offer?.prices[0]?.payout : '0.00' }} {{ $t('form.UAH') }}</div>
                </v-card-text>
              </v-card>

              <v-card class="mt-4">
                <v-card-text>
                  <div class="text-h5 font-weight-bold">{{ $t('form.call_center') }}</div>
                  <div>{{ $t('form.works') }}</div>
                </v-card-text>
              </v-card>

              <v-card v-if="offer?.landings?.length" class="mt-4">
                <v-card-text>
                  <div class="text-h5 font-weight-bold mb-4">{{ $t("form.landings") }}</div>
                  <v-row v-if="offer.landings?.length">
                    <v-col
                        cols="12"
                        class="pt-0 pb-0"
                        v-for="(item, index) in offer?.landings"
                        :key="index"
                    >
                      <v-col cols="12" class="px-10 py-0">
                        <div
                            class="font-weight-bold"
                            :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
                        >
                          {{ item.name }}
                        </div>
                      </v-col>
                      <v-col cols="12" class="px-10 py-0">
                        <v-btn
                            :href="item.url"
                            target="_blank"
                            text
                            class="pa-0"
                            style="text-decoration: underline; color: inherit;"
                        >
                          {{ $vuetify.breakpoint.mobile ? reducedName(item.url) : reducedBigName(item.url) }}
                        </v-btn>
                      </v-col>
                    </v-col>
                    <v-divider/>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card class="mt-4">
                <v-card-text>
                  <div class="text-h5 font-weight-bold">{{ $t('form.geo') }}</div>
                  <v-chip v-for="geo in geos" :key="geo.name" class="mr-2">{{ geo.country_code }} {{ geo.name }}</v-chip>
                </v-card-text>
              </v-card>
<!--              <v-card class="mt-4">
                <v-card-text>
                  <div class="text-h5 font-weight-bold">{{ $t('form.additional') }}</div>
                  <v-btn :href="offer.link" text color="primary">{{ $t('btn.details') }}</v-btn>
                </v-card-text>
              </v-card>

              <v-card class="mt-4">
                <v-card-text>
                  <div class="text-h5 font-weight-bold">{{ $t('form.You_may_be_interested') }}</div>
                  <v-slide-group show-arrows>
                    <v-slide-item v-for="item in offer.addons" :key="item.name">
                      <v-card class="ma-2" max-width="150">
                        <v-img :src="item.image" aspect-ratio="1"></v-img>
                        <v-card-subtitle class="font-weight-bold">{{ item.name }}</v-card-subtitle>
                        <v-card-text>{{ item.price }} {{ $t('form.UAH') }}</v-card-text>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </v-card-text>
              </v-card>-->
            </v-col>
          </v-row>
        </v-container>

        <!-- Description and Characteristics Section -->
        <v-container>
          <v-row>
            <v-col>
<!--              <h2 class="mt-4 mb-2" style="color: black">Характеристики</h2>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>Розмір рами</td>
                    <td>{{ product.frameSize }}</td>
                  </tr>
                  <tr>
                    <td>Клас</td>
                    <td>{{ product.class }}</td>
                  </tr>
                  <tr>
                    <td>Кількість швидкостей</td>
                    <td>{{ product.speeds }}</td>
                  </tr>
                  <tr>
                    <td>Діаметр колеса</td>
                    <td>{{ product.wheelDiameter }}</td>
                  </tr>
                  <tr>
                    <td>Матеріал рами</td>
                    <td>{{ product.frameMaterial }}</td>
                  </tr>
                  <tr>
                    <td>Тип гальм</td>
                    <td>{{ product.brakeType }}</td>
                  </tr>
                  <tr>
                    <td>Колір</td>
                    <td>{{ product.color }}</td>
                  </tr>
                  <tr>
                    <td>Каретка</td>
                    <td>{{ product.bottomBracket }}</td>
                  </tr>
                  <tr>
                    <td>Кермова колонка</td>
                    <td>{{ product.headset }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>-->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <add-edit-stream-dialog
      v-if="streamDialog"
      :inputOffer="offer"
      :visible="streamDialog"
      @close="streamDialog = false"
      :type="streamDialogType"
    />
  </div>
</template>

<script>
import offersCatalogMixin from "@/components/offersCatalog/offersCatalogMixin";
import AddEditStreamDialog from "@/components/streams/addEditStreamDialog.vue";
import user from "@/mixins/user";
import offersService from "@/services/request/offers/offersService";
import loader from "@/mixins/loader";

export default {
  name: "OffersCatalogInfoDialog",
  components: { AddEditStreamDialog },
  mixins: [offersCatalogMixin, user, loader],
  data: () => ({
    showFullDescription: false,
    offer: { images: [], description: '', fullDescription: '' },
    geos: [],
    streamDialog: false,
    streamDialogType: 'create',
    isDescriptionTooLong: false,
  }),
  async mounted() {
    this.setLoading(true);
    await this.getOffer();
    await this.getGeo();
    this.geos = this.geoList.filter(item => item.id === this.offer.geo_id);
    this.setLoading(false);
    this.checkDescriptionOverflow();
  },
  methods: {
    reducedName(item) {
      return item.substring(0, 20) + "...";
    },
    reducedBigName(item) {
      return item.length > 50 ? item.substring(0, 50) + "..." : item;
    },
    async getOffer() {
      try {
        this.offer = await offersService.getOffer(this.$route.params.product);
      } catch (e) {
        console.error(e);
      }
    },
    onBack() {
      this.$router.push({ name: "offersCatalog" }).catch(() => {});
    },
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
    createStream() {
      this.streamDialog = true;
    },
    checkDescriptionOverflow() {
      this.$nextTick(() => {
        const wrapper = this.$refs.descriptionWrapper;
        const text = this.$refs.descriptionText;
        if (text.scrollHeight > wrapper.clientHeight) {
          this.isDescriptionTooLong = true;
        }
      });
    },
  },
  computed: {
    getFullPrice() {
      const fullPrice = Number(this.offer.average_margin) + Number(this.offer.price);
      return fullPrice.toFixed(2);
    },
  },
};
</script>

<style scoped>
.description-wrapper {
  max-height: 150px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.description-wrapper.expanded {
  max-height: none;
}
</style>
